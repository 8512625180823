import { post } from '@rails/request.js'

const onSubmitCaptcha = async () => {
  const form = document.getElementById('get-started-form')
  const formData = new FormData(form)
  const response = await post('/signup', {
    body: formData,
    responseKind: 'turbo-stream',
    headers: { 'Turbo-Frame': true },
  })
}

window.onSubmitCaptcha = onSubmitCaptcha
